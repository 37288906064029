.actions {
    text-align: center;
  }
  .profileUploadImage {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 25%;
  }
  .hide_file {
    background-color: transparent;
    border: 1px solid #8a2b06;
  }
.disabledbutton{
  cursor:not-allowed;
    font: inherit;
    background-color: transparent;
    border: 1px solid #8a2b06;
    padding: 0.5rem 2rem;
    border-radius: 25px;
    margin-left: 1rem;
}
.enabledbutton{
  font: inherit;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #8a2b06;
    padding: 0.5rem 2rem;
    border-radius: 25px;
    margin-left: 1rem;
}
.enabledbutton button:hover,
.enabledbutton button:active {
    background-color: #5a1a01;
    border-color: #5a1a01;
    color: white;
  }
.profileModalHeader{
  text-align: center;
}
.profileImageInputFile{
  display: none;
}