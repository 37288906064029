@import url('https://fonts.googleapis.com/css?family=Lato:400,700');
.profilecontainer {
    background: linear-gradient(rgb(156, 225, 234),rgb(115, 156, 222));
    box-shadow: 5px 5px 5px rgba(#d46f6f, .5);
    border-radius: 10px;
    width: max-content;
    /* margin: 120px 440px; */
    position: absolute;
    /* top: 200px;
    left:650px; */
    top: 30%;
    left:30%;
    height: max-content;
  }
  .firstpart{
    display: flex;
    justify-content: center;
    align-content: center;
    column-count: 3;
    column-gap: 10px;
  }
  .personalDetail{
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  .personalDetailtitle{
       color: green;
      font-weight: bold;
      font-size: 25px;
  }
  .profileimagediv{
    padding: 45px 0px 0px;
  }
  .profileimage {
    width: 150px;
    height: 150px; 
    border-radius: 50%;
    cursor: pointer;
  }
 
  .header{
    color: #080604;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  .secondpart{
    column-count: 2;
    column-gap: 20px;
    margin-left: 30px;
    /* column-width: 10px; */
  }
  
  .title{
      color: green;
      font-weight: bold;
  }
  .detail{
    color: brown;
    font-weight: bold;
    font-size: medium;
    word-break: break-all;
  }
  .login__submit {
	background: #fff;
	font-size: 14px;
	margin-top: 10px;
	padding: 16px 12px;
	border-radius: 26px;
	border: 1px solid #D4D3E8;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	align-items: center;
	width: 70%;
	color: #4C489D;
	box-shadow: 0px 2px 2px #5C5696;
	cursor: pointer;
	transition: .2s;
}
.login__submit:active,
.login__submit:focus,
.login__submit:hover {
	border-color: #6A679E;
	outline: none;
}


.button__text{
    text-align: center;
  }

  