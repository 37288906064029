@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

.logincontainer {
	display: flex;
	align-items: center;
	justify-content: center;
	/* padding-top: 150px; */
	position: fixed;
	left: 50%;
	right: 50%;
	top:50%;
	bottom:50%;
}

.loginscreen {		
	background: linear-gradient(90deg, #5D54A4, #7C78B8);		
	position: relative;	
	height: 700px;
	width: 400px;	
	box-shadow: 0px 0px 24px #5C5696;
}

.loginheading{
  font-weight: bold;
  padding: 0 15px 0 90px;
}

.screen__content {
	z-index: 1;
	position: relative;	
	height: 100%;
}

.screen__background {		
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);	
}

.screen__background__shape {
	transform: rotate(45deg);
	position: absolute;
}

.screen__background__shape1 {
  transform: rotate(45deg);
	position: absolute;
	height: 520px;
	width: 520px;
	background: #FFF;	
	top: -50px;
	right: 120px;	
	border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
  transform: rotate(45deg);
	position: absolute;
	height: 220px;
	width: 220px;
	background: #6C63AC;	
	top: -172px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape3 {
  transform: rotate(45deg);
	position: absolute;
	height: 540px;
	width: 190px;
	background: linear-gradient(270deg, #5D54A4, #6A679E);
	top: -24px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape4 {
  transform: rotate(45deg);
	position: absolute;
	height: 400px;
	width: 200px;
	background: #7E7BB9;	
	top: 420px;
	right: 50px;	
	border-radius: 60px;
}

.login {
	width: 420px;
	padding: 120px 50px 50px 50px;
}

.login__field {
	padding: 20px 0px;	
	position: relative;	
}


.login__input {
	border: none;
	border-bottom: 2px solid #D1D1D4;
	background: none;
	padding: 10px;
	padding-left: 24px;
	font-weight: 700;
	width: 55%;
	transition: .2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
	outline: none;
	border-bottom-color: #6A679E;
}

.login__submit {
	background: #fff;
	font-size: 14px;
	margin-top: 30px;
	padding: 16px 20px;
	border-radius: 26px;
	border: 1px solid #D4D3E8;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	align-items: center;
	width: 50%;
	color: #4C489D;
	box-shadow: 0px 2px 2px #5C5696;
	cursor: pointer;
	transition: .2s;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
	border-color: #6A679E;
	outline: none;
}

.errormessage{
	/* background-color: red; */
	color: red;
}


.button__text{
  text-align: center;
}
@media only screen and (max-width: 720px) {
    html {
      overflow: hidden;
    }
  }
