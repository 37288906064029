/* * {
    box-sizing: border-box;
  } */
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  /* Add some padding inside the card container */
  .containermember {
    padding: 2px 16px;
  }
  .memberImage{
    /* border-radius: 5px 5px 0 0; */
    width: 200px;
    height: 300px;
    cursor: pointer;
    border-radius: 50%;
  }


  
  /* Style the counter cards */
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
    padding: 16px;
    text-align: center;
    background-color: #f1f1f1;
    /* Add shadows to create the "card" effect */
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
    transition: 0.3s;
    border-radius: 5px; /* 5px rounded corners */
    padding: auto;
  }
.contactIcon{
    /* height: 20px; */
    width: 20px;
    display: flex;
    margin: 3% 0 0 35%;
}
  
 