$pencil-green: #09d56e;
$pencil-dark: #232123;
$body-bg: #010001;

.bodycenter {
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  color: white;
  font-size: 1.5rem;
//   padding-left: 50%;
  padding: 70px 0;
  background-color: transparent;
}

.pencil {
  @keyframes pencil-animation {
    0% {
      transform: rotate(135deg);
    }

    20% {
      transform: rotate(315deg);
    }

    45% {
      transform: translateX(300px) rotate(315deg);
    }

    55% {
      transform: translateX(300px) rotate(495deg);
    }

    100% {
      transform: rotate(495deg);
    }
  }

  width: 345px;
  height: 40px;
  transform-origin: center;
  transform: rotate(135deg);
  animation: pencil-animation 10s infinite;
  background-color: transparent;

  &__ball-point {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: $pencil-green;
    height: 10px;
    width: 10px;
    border-radius: 50px;
  }

  &__cap {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    clip-path: polygon(20% 40%, 100% 0%, 100% 100%, 20% 60%);
    background: $pencil-dark;
    width: 12%;
    height: 100%;
  }

  &__cap-base {
    position: absolute;
    left: 12%;
    top: 0;
    height: 100%;
    width: 20px;
    background: $pencil-dark;
  }

  &__middle {
    position: absolute;
    left: calc(12% + 20px);
    top: 0;
    height: 100%;
    width: 70%;
    background: $pencil-green;
  }

  &__eraser {
    position: absolute;
    left: calc(12% + 70% + 20px);
    top: 0;
    height: 100%;
    width: 11%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: $pencil-dark;
  }
}

.line {
  @keyframes line-animation {
    20% {
      transform: scaleX(0);
    }

    45% {
      transform: scaleX(0.6);
    }

    55% {
      transform: scaleX(0.6);
    }

    100% {
      transform: scaleX(0);
    }
  }

  position: relative;
  top: 80px;
  right: 103px;
  height: 10px;
  width: 1000px;
  z-index: -1;
  border-radius: 50px;
  background: $pencil-green;
  transform: scaleX(0);
  transform-origin: center;
  animation: line-animation 10s infinite;
}

// h2 {
//   position: relative;
//   top: 150px;
//   right: 75px;
// }
