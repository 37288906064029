.odcontainer{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 15%;
    left:35%;
}
.odscreen{
    background: linear-gradient(90deg, #5D54A4, #7C78B8);		
	position: relative;	
	height: max-content;
	width: 600px;	
	box-shadow: 0px 0px 24px #5C5696;
}
.orgDetail{
    width: 420px;
	padding: 20px 20px 50px 50px;
  }
.orgDetailHeading{
    font-weight: bold;
    padding: 0 0 0 20px;
}
.screen__content {
	z-index: 1;
	position: relative;	
	height: 100%;
}

.screen__background {		
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);	
}

.screen__background__shape1 {
  transform: rotate(45deg);
	position: absolute;
	height: 620px;
	width: 520px;
	background: #FFF;	
	top: -50px;
	right: 120px;	
	border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
  transform: rotate(45deg);
	position: absolute;
	height: 220px;
	width: 220px;
	background: #6C63AC;	
	top: -172px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape3 {
  transform: rotate(45deg);
	position: absolute;
	height: 540px;
	width: 190px;
	background: linear-gradient(270deg, #5D54A4, #6A679E);
	top: -24px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape4 {
  transform: rotate(45deg);
	position: absolute;
	height: 400px;
	width: 200px;
	background: #7E7BB9;	
	top: 420px;
	right: 50px;	
	border-radius: 60px;
}



.od__input:active,
.od__input:focus,
.od__input:hover {
	outline: none;
	border-bottom-color: #6A679E;
}

.od__submit {
	background: #fff;
	font-size: 14px;
	margin-top: 30px;
	padding: 16px 20px;
	border-radius: 26px;
	border: 1px solid #D4D3E8;
	text-transform: uppercase;
	font-weight: 700;
	color: #4C489D;
	box-shadow: 0px 2px 2px #5C5696;
	cursor: pointer;
	transition: .2s;
}

.od__submit:active,
.od__submit:focus,
.od__submit:hover {
	border-color: #6A679E;
	outline: none;
}
.odlabel{
	font-size: 12px;
	font-weight: bold;
	color: #4a4382;
}
.odtextarea{
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: none;
  }
  .od__field {
	padding: 10px 0px;	
	position: relative;	
    
}

.od__input {
	border: none;
	border-bottom: 2px solid #D1D1D4;
	background: none;
	padding: 10px;
	font-weight: 700;
	width: 100%;
	transition: .2s;
}
.odbutton{
    display: flex;
    align-items: center;
    justify-content: center;
}
.successmessage{
    color: green;
    font-size: 12px;
    margin-bottom: -17px;
}
.errormessage{
    color: red;
    font-size: 12px;
    margin-bottom: -17px;
}
