.addPhotoHeader{
    text-align: center;
}
.addPhotoLabel{
    text-decoration: solid;
    font-size: 25px;
    font-weight: bold;
    /* margin-left: 50px; */
}
.addPhotoselect{
    font-size: 20px;
    font-weight: bold;
    width: 360px;
    margin-left: 10px;
}
.addPhototextarea{
  width: 100%;
  height: 80px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}
.disabledbutton{
    cursor:not-allowed;
      font: inherit;
      background-color: transparent;
      border: 1px solid #8a2b06;
      padding: 0.5rem 2rem;
      border-radius: 25px;
      margin-left: 1rem;
}
.addEnablePhotobutton{
    font: inherit;
      cursor: pointer;
      background-color: transparent;
      border: 1px solid #8a2b06;
      padding: 0.5rem 2rem;
      border-radius: 25px;
      margin-left: 1rem;
}
.addEnablePhotobutton button:hover,
.addEnablePhotobutton button:active {
      background-color: #5a1a01;
      border-color: #5a1a01;
      color: white;
    }
.addphotoinputfile{
    display:none
}