.imagepreviewmultiple{
    width: 90px;
    height: 90px;
    border-radius: 15%;
    cursor: pointer;
    /* margin-left: 25%; */
    margin-left: 10px;
}
.ImagePreviewheading{
    text-align: center;
    margin-top: 2px;
}
.ImagePreviewclose{
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: gray;
    margin-top: 10px;
}