.photogallery{
    position: absolute;
  left:18%;
  width: 80%;
  top: -10px;
}
.photogalleryLabel{
    text-decoration: solid;
    font-size: 40px;
    font-weight: bold; 
    margin-left: 50px;
    position:inline;
    margin-top: 100px;
}
.photogalleryselect{
    /* text-decoration: solid; */
    font-size: 30px;
    font-weight: bold;
    width: 660px;
    margin-left: 10px;
    position:inline;
    margin-top: 100px;
}

.photogalleryButton{
    height: 40px;
    width: 150px;
    /* border: 30px; */
    position:inline;
    box-shadow: inset;
    border-radius: 9%;
    cursor: pointer;
    margin-left: 200px;
    font-size: 18px;
    font-weight: bold;
    background-color: rgb(173, 198, 70);
    margin-top: 100px;
}
.photoGalleryRow{
    margin: 0 -2px;
}
.photoGalleryColumn{
    float: left;
    width: 30%;
  padding: 0 8px;
}
.photoGalleryRow:after {
    content: "";
    display: table;
    clear: both;
  }
@media screen and (max-width:1524px){
    .photoGalleryColumn{
        float: left;
        width: 40%;
      padding: 0 8px;
    }
}