.header {
  width: auto;
  height: 70px;
  background-color: #8d45bd;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  position: relative; 
  top:0;
}

.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.header li {
  margin: 0 1rem;
  cursor: pointer;
}

.header a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.header button {
  font: inherit;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 150%;
}

.header a:hover {
  color: #c291e2;
}

.header button:hover {
  background-color: #c291e2;
  color: #6f21a3;
}