/* .photodisplaydiv{
    background-color: white;
    box-shadow: 0 4px 18px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; 
  margin-top: 10px;
  margin-bottom: 15px;
}
.photogalleryImage{
    width: 300px;
    height: 200px;
    border: 10px;
}
.displayImage:hover {
    border: 1px solid #777;
  } */
  .Post {

    border-radius: 3px;

    border: 1px solid #e6e6e6;

    background-color: #fff;


  }

  .Post-user {

    display: flex;

    padding: 16px;

    align-items: center;

  }

  .Post-user-profilepicture {

    width: 30px;

    height: 30px;

  }

  .Post-user-profilepicture img {

    width: 100%;

    height: 100%;

    border-radius: 50%;

  }

  .Post-user-nickname {

    margin-left: 12px;

    font-family: 'PT Sans', sans-serif;

    font-weight: bold;

  }


 

  .Post-image img {
    height: 200px;
    width: 100%; 

  }

  .Post-caption {

    padding: 16px 16px;

  }

  .Post-caption strong {

    font-family: 'PT Sans', sans-serif;

    font-weight: bold;

  }

  /* .vjs-fade-out {

    display: none;

    visibility: hidden;

    opacity: 0;

  } */
  @media screen and (max-width:960px) {
    .lightbox-image{
      pointer-events: none;
      
    }
  }
  
  
  .lightbox-image{
    cursor: pointer;
  }