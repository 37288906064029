.donationform{
    display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 120px;
	 position: fixed;
      left: 600px;
      top: 50px;
	overflow: hidden;
}
.donationscreen{
    background: linear-gradient(90deg, #ffffff, #e0e0e5);		
	position: relative;	
	height: 600px;
	width: 720px;	
	/* box-shadow: 2px 2px 24px #d9d8e5; */
}
.donationheading{
    z-index: 1;
	position: relative;	
	height: 100%;
}

.donationLabel{
    font-size: 20px;
    font-weight: bold;
}
.donationfield{
    padding: 5px 5px;
}
.donationinput{
    border: none;
	background: none;
	font-weight: 700;
    font-size: 20px;
    width: 300px;
    display: inline-block;
    word-wrap: break-word;
    
}
.donationinput:hover,
.donationinput:active,
.donationinput:focus{
    outline: none;
	border-bottom-color: #80cb8f;
}

.donationheader{
  font-weight: bold;
  padding: 0 0 0 500px;
  font-size: 25px;
  
}
.donationbody{
	padding: 30px;	
}
.donationimage{
    position:fixed;
    left:1100px;
    top: 250px;
}
.donationimage img{
    width: 180px;
    height: 160px;
    border-radius: 50%;
}
.donationscreen__background {		
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);	
}
.donationscreen__background__shape1 {
    transform: rotate(45deg);
      position: absolute;
      height: 520px;
      width: 920px;
      background: #FFF;	
      top: -50px;
      right: 100px;	
      border-radius: 0 72px 0 0;
  }
  
  .donationscreen__background__shape2 {
    transform: rotate(45deg);
      position: absolute;
      height: 920px;
      width: 220px;
      background: #7dde9c;	
      top: -102px;
      right: 0;	
      border-radius: 32px;
  }
  
  .donationscreen__background__shape3 {
    transform: rotate(55deg);
      position: absolute;
      height: 540px;
      width: 490px;
      background: linear-gradient(270deg, #8fd295, #7dde9c);
      top: -154px;
      right: 0;	
      border-radius: 52px;
  }
  
  .donationscreen__background__shape4 {
    transform: rotate(45deg);
      position: absolute;
      height: 600px;
      width: 200px;
      background: #6bd285;	
      top: 420px;
      right: 50px;	
      border-radius: 60px;
  }
  .donationbuttondiv{
    padding: 16px 160px;

  }
  .donatebutton{
        background-color: rgb(190, 183, 183);
        font-size: 20px;
        font-weight: bold;
        padding: 7px 7px;
        cursor: pointer;
        width: 200px;
        background: linear-gradient(rgb(94, 186, 140),rgb(85, 142, 106));
  }
  .donationtextarea{
      resize: none;
      width: 50%;
      border: none;
      font-size: 17px;
      font-weight: bold;
  }
  .donationaddressLabel{
      font-size: 20px;
      font-weight: bold;
      
  }
  