.memberdirectory{
  position: absolute;
  left:18%;
  width: 80%;
  top: -10px;
}
/* Float four columns side by side */
.column {
  float: left;
  width: 30%;
  padding: 0 10px;
}

/* Remove extra left and right margins, due to padding in columns */
.row {
  margin: 0 -5px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.search {
  border: 1px solid grey;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  padding: 2px 23px 2px 30px;
  outline: 0;
  background-color: #f5f5f5;
  margin-top: 90px;
  margin-bottom: 10px;
}

/* Responsive columns - one column layout (vertical) on small screens */
@media screen and (max-width: 1524px) {
  .column {
    float: left;
    width: 40%;
   padding: 0 10px;
  }
}
